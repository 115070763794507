<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="openModal"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Method</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getMethods"
              @onEdit="onEdit"
              @onDelete="onDelete"
              @updatePosDown="updatePosDown"
              @updatePosUp="updatePosUp"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <PaymentMethodsForm
      v-if="showModal"
      :item="edittingItem"
      @close="closeModal"
      @onSave="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import PaymentMethodsForm from './PaymentMethodsForm.vue';

export default {
  components: {
    VueTable,
    PaymentMethodsForm,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'payment_method_id',
            mask: 'id',
            sortable: true,
          },
          {
            title: 'name',
            sortable: true,
          },
          {
            title: 'help_text',
            mask: 'help text',
            sortable: true,
          },
          {
            title: 'commissionable',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Update Position Down',
            callback: 'updatePosDown',
            icon: 'expand_more',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Update Position Up',
            callback: 'updatePosUp',
            icon: 'expand_less',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Method',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Method',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      edittingItem: null,
      showModal: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getMethods(params) {
      this.request('payment_methods'.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onEdit(item) {
      this.edittingItem = item;
      this.openModal();
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.edittingItem = null;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Method',
        'Are you sure you want to delete this Method?',
      ).then(() => {
        this.request(`payment_methods/${item.payment_method_id}`, 'delete', null, () => {
          this.fireSuccess('Method deleted successfully.');
          this.$refs.vtable.init();
        });
      });
    },
    updatePosUp(item) {
      this.updatePosition(item, -1);
    },
    updatePosDown(item) {
      this.updatePosition(item, +1);
    },
    // eslint-disable-next-line camelcase
    updatePosition(item, position_modifier) {
      this.loading = true;
      this.request(`payment_methods/${item.payment_method_id}/position`, 'put', { position_modifier }, () => {
        this.fireSuccess('Position updated succesfully!');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style scoped>
</style>
