<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Methods Form
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <form @submit.prevent="validate">
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <form-text
                  v-model="model.name"
                  name="name"
                  rules="required"
                  icon="face"
                  label="Name"
                />
                <form-text
                  v-model="model.help_text"
                  name="help_text"
                  icon="help"
                  label="Help Text"
                />
                <md-switch
                  v-model="model.commissionable"
                >
                  Commissionable
                </md-switch>
              </div>
            </div>
          </md-card-content>
        </form>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="btn-previous"
        @click="$emit('close')"
      >
        Cancel
      </md-button>
      <md-button
        class="md-success"
        @click="saveMethod"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { FormText } from '@/components/Inputs';

extend('required', required);

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      model: {},
    };
  },
  mounted() {
    if (this.item) this.model = this.item;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveMethod() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.item) {
            this.request(`payment_methods/${this.model.payment_method_id}`, 'put', this.model, () => {
              this.fireSuccess('Payment method updated successfully!');
              this.$emit('onSave');
              this.close();
            });
          } else {
            this.request('payment_methods', 'post', this.model, () => {
              this.fireSuccess('New payment method has been created!');
              this.$emit('onSave');
              this.close();
            });
          }
        }
      });
    },
  },
};
</script>
